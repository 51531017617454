// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  Box, ListItemIcon, ListItemText, type Theme, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getManagerInfo } from "@fas/cpa-state-manager/redux/actions";
import type { ManagerInfoComponentProps } from "@fas/cpa-state-manager";
import { ManagerInfo as ManagerInfoContainer } from "@fas/cpa-state-manager";
import { Email, Telegram, SupervisorAccount } from "@mui/icons-material";
import type { Classes } from "@fas/cpa-cabinet-ui/lib/ManagerInfo/ManagerInfo.types";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/ManagerInfo.types";
import Skype from "../../icons/Skype";
// $FlowFixMe remove if not empty
const mapStateToProps: () => StateToProps = (): StateToProps => ({});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetManagerInfo: getManagerInfo,
}, dispatch);

const useStyles: () => Classes = makeStyles((
  theme: Theme
): Classes => ({
  layout: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    color: "rgba(0, 0, 0, 0.5)",
    background: "rgba(0, 0, 0, 0.05)",
    fontSize: "12px",
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(5),
    },
    minWidth: theme.spacing(25),
  },
  name: {
    color: theme.palette.text.primary,
    lineHeight: "18px",
    paddingLeft: theme.spacing(2),
  },
}));

function ContactInfoItem({
  contactType,
  onClick,
}: { contactType: string, onClick?: () => mixed }): Node {
  const IconsMapper: {[key: string]: Node} = {
    name: <SupervisorAccount />,
    email: <Email />,
    skype: <Skype />,
    telegram: <Telegram />,
  };

  const icon: Node = IconsMapper[contactType];
  const text: string = useSelector((state): string => state.managerInfo.get(contactType));

  return Boolean(text) && (
    <Box onClick={onClick} size="small" display="flex" px={2} my={0.5} style={{ cursor: onClick ? "pointer" : "inherit" }}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: "body2",
          style: {
            whiteSpace: "break-spaces",
            overflowWrap: "break-word",
          },
        }}
        primary={text}
      />
    </Box>
  );
}

const ManagerInfo: StatelessFunctionalComponent<Props> = ({
  onGetManagerInfo,
}: Props) => {
  const classes: Classes = useStyles();
  useEffect(() => {
    onGetManagerInfo();
  }, [onGetManagerInfo]);

  return (
    <ManagerInfoContainer
      renderComponent={({
        contacts,
      }: ManagerInfoComponentProps): Node => (
        <Box data-testid="manager_info-layout" className={classes.layout}>
          <Typography className={classes.name}>Manager Contacts</Typography>
          {[{ type: "name", onClick: undefined }, ...contacts].map(({ type, onClick }): Node => (
            <ContactInfoItem
              key={`manager_info-contact-${type}`}
              data-testid={`manager_info-contact-${type}`}
              contactType={type}
              onClick={onClick}
            />
          ))}
        </Box>
      )}
    />
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ManagerInfo);
