// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import DocumentTitle from "react-document-title";
import {
  Box, Button, TextField, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changePassword } from "../../services/request";
import { catchError } from "../../actions/error/actions";

type Props = {}

const useStyles = makeStyles((theme) => ({
  input: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    ...theme.typography.gradientTitle,
  },
}));

const ChangePassword: StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    token: (new URLSearchParams(location.search)).get("token") || "",
    confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = async ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const { token, password, confirmation } = formData;
    setLoading(true);
    try {
      await changePassword({ token, password, confirmation });
      navigate("/login");
    }
    catch (r) {
      if (!r.response) {
        dispatch(catchError(r));
      }
      else {
        const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
        setErrorMessage(message);
      }
      setLoading(false);
    }
  };

  return (
    <DocumentTitle title="Affiliate Change Password">
      <Box onKeyPress={onKeyPress}>
        <Typography variant="h2" className={classes.title}>
          Change Password
        </Typography>
        <TextField
          label="Password"
          fullWidth
          autoFocus
          placeholder="Input your password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className={classes.input}
          error={Boolean(errorMessage)}
        />
        <TextField
          label="Confirm password"
          fullWidth
          placeholder="Confirm your password"
          type="password"
          name="confirmation"
          value={formData.confirmation}
          onChange={handleChange}
          className={classes.input}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          name="next"
          onClick={handleSubmit}
          disabled={loading}
        >
          <Box width="100%">Change</Box>
        </Button>
      </Box>
    </DocumentTitle>
  );
};

export default ChangePassword;
