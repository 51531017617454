// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import {
  Route,
  Routes,
  Navigate as RouterRedirect,
  BrowserRouter,
} from "react-router-dom";
import environment from "environment";
import {
  Box, Container, CssBaseline, Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CabinetProvider } from "@fas/cpa-state-manager/services/CabinetProvider";
import { ConfirmProvider } from "material-ui-confirm";
import Postbacks from "../Postbacks";
import type {
  DispatchToProps, OwnProps, Props, StateToProps,
} from "./types/App.types";
import defaultTheme from "../../theme";
import PrivateRoute from "../../components/PrivateRoute";
import Navigation from "../../components/Navigation";
import { logoutRedirect as LogoutRedirect } from "../../services/request";
import Dashboard from "../Dashboard";
import MainReport from "../MainReport";
import TransactionReport from "../TransactionReport/TransactionReport";
import PayoutHistoryReport from "../PayoutHistoryReport";
import Profile from "../Profile";
import Login from "../../components/Login";
import Unsubscribe from "../../components/Unsubscribe/Unsubscribe";
import NotificationsContainer from "../NotificationsContainer";
import AuthField from "../../components/Login/AuthField";
import RestorePassword from "../../components/Login/RestorePassword";
import ChangePassword from "../../components/Login/ChangePassword";
import Autologin from "../../components/Login/Autologin";

// $FlowFixMe
const mapStateToProps: (*) => StateToProps = () => ({});

// $FlowFixMe
const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const useStyles = makeStyles(() => ({
  content: {
    height: "calc(100vh)",
    flexGrow: 1,
    minWidth: "500px",
  },
  body: {
    maxWidth: "1600px",
  },
  toolbar: {
    minHeight: "57px",
  },
}));

const App: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const classes = useStyles();
  return (
    <ConfirmProvider>
      <CabinetProvider environment={environment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <NotificationsContainer />
            <BrowserRouter>
              <Box display="flex">
                <Routes>
                  <Route path="/unsubscribe/:token" element={<Navigation isShowAction={false} />} />
                  <Route path="/login" element={<Navigation isShowAction={false} />} />
                  <Route path="/restorePassword" element={<Navigation isShowAction={false} />} />
                  <Route path="/changePassword" element={<Navigation isShowAction={false} />} />
                  <Route path="/autologin" element={<Navigation isShowAction={false} />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="*" element={<Navigation isShowAction />} />
                  </Route>
                </Routes>
                <main
                  className={classes.content}
                >
                  <Toolbar className={classes.toolbar} />
                  <Box component={Container} py={3} className={classes.body}>
                    <Routes>
                      <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
                      <Route element={<Login />}>
                        <Route path="/login" element={<AuthField />} />
                        <Route path="/restorePassword" element={<RestorePassword />} />
                        <Route path="/changePassword" element={<ChangePassword />} />
                        <Route path="/autologin" element={<Autologin />} />
                      </Route>

                      <Route element={<PrivateRoute />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/postbacks" element={<Postbacks />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/payoutHistory" element={<PayoutHistoryReport />} />
                        <Route path="/mainReport" element={<MainReport />} />
                        <Route path="/transactionReport" element={<TransactionReport />} />
                        <Route path="/logout" element={<LogoutRedirect />} />
                        <Route path="*" element={<RouterRedirect to="/dashboard" />} />
                      </Route>
                    </Routes>
                  </Box>
                </main>
              </Box>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </CabinetProvider>
    </ConfirmProvider>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(App);
