const getEndpoints: ({ baseUrl: string }) => Environment = ({ baseUrl }) => ({
  baseUrl,
  links: {},
  endpoints: {
    get: {
      getDropdownList: `${baseUrl}/api/v1/dropdown`,
      getDefaultPostback: `${baseUrl}/api/v1/defaultPostback`,
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      defaultPostback: `${baseUrl}/api/v1/defaultPostback`,
      additionalPostbacks: `${baseUrl}/api/v1/additionalPostback`,
      personalInfo: `${baseUrl}/api/v1/userInfo`,
      contactInfo: `${baseUrl}/api/v1/contactInfo`,
      paymentInfo: `${baseUrl}/api/v1/paymentInfo`,
      managerContactInfo: `${baseUrl}/api/v1/managerContactInfo`,
      getPaymentInfo: `${baseUrl}/api/v1/paymentInfo`,
    },
    post: {
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      getPayoutHistory: `${baseUrl}/api/v1/payoutHistory`,
      getDashboardData: `${baseUrl}/api/v1/reportDashboard`,
      getTransactionReportData: `${baseUrl}/api/v1/reportTransaction`,
      getMainReportData: `${baseUrl}/api/v1/reportMain`,
      login: `${baseUrl}/api/v1/auth/login`,
      restorePassword: `${baseUrl}/api/v1/auth/restorePassword`,
      changePassword: `${baseUrl}/api/v1/auth/changePassword`,
      autologin: `${baseUrl}/api/v1/auth/autologin`,
      unsubscribe: `${baseUrl}/api/v1/auth/unsubscribe`,
      downloadMainReport: `${baseUrl}/api/v1/reportMainExport`,
      downloadTransactionReport: `${baseUrl}/api/v1/reportTransactionExport`,
      paymentInfo: `${baseUrl}/api/v1/paymentInfo`,
    },
    put: {
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
    },
    delete: {
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
    },
    redirect: {},
  },
});

export default getEndpoints;