// @flow
import React, {
  type StatelessFunctionalComponent, useEffect, useState,
} from "react";
import DocumentTitle from "react-document-title";
import {
  Box, Button, Container, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { type MessageResponse, unsubscribe } from "../../services/request";
import { catchError } from "../../actions/error/actions";

type Props = {};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    ...theme.typography.gradientTitle,
  },
}));

const Unsubscribe: StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [message, setMessage] = useState("");

  useEffect(() => {
    unsubscribe({ token })
      .then((r: MessageResponse) => {
        setMessage(r.message);
      })
      .catch((r) => {
        if (!r.response) {
          dispatch(catchError(r));
        }
        const msg: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
        setMessage(msg);
      });
  }, [token]);

  return (
    <DocumentTitle title="Affiliate Unsubscribe">
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center" p={2} boxShadow={2}>
          <Box>
            <Typography variant="h2" className={classes.title}>
              Unsubscribe
            </Typography>
            <Typography className={classes.message}>
              {message}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              name="next"
              onClick={() => {
                navigate("/");
              }}
            >
              <Box width="100%">Go to dashboard</Box>
            </Button>
          </Box>
        </Box>
      </Container>
    </DocumentTitle>
  );
};

export default Unsubscribe;
