// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { isAuthenticated } from "../../services/request";

type Props = {};

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Login: StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const location = useLocation();

  if (isAuthenticated()) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Navigate to={from} />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf-NOIeAAAAAI1MzsYU2nIQGzq300SFFhw55Q-Z">
      <Container component="main" maxWidth="xs" className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center" p={2} boxShadow={2}>
          <Outlet />
        </Box>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
