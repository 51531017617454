// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import {
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import DocumentTitle from "react-document-title";
import {
  Box, Button, TextField, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { type AuthResponse, login, setAuthToken } from "../../services/request";
import ButtonLink from "./ButtonLink";
import { catchError } from "../../actions/error/actions";

type Props = {}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  input: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(2),
    ...theme.typography.gradientTitle,
  },
}));

const AuthField: StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
    setErrorMessage("");
  };

  const onKeyPress = ({ charCode }: { charCode: number }) => {
    const enterCharCode: number = 13;
    if (charCode === enterCharCode) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    executeRecaptcha()
      .then((token) => login({ ...formData, captchaResponse: token })
        .then((r: AuthResponse) => {
          setAuthToken(r.payload.token, new Date(r.payload.expires * 1000));
          const { from } = location.state || { from: { pathname: "/" } };
          navigate(from.pathname);
        }).catch((r) => {
          if (!r.response) {
            dispatch(catchError(r));
          }
          else {
            const message: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
            setErrorMessage(message);
          }
          setLoading(false);
        }));
  };

  return (
    <DocumentTitle title="Affiliate Login">
      <Box onKeyPress={onKeyPress} className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          Sign In
        </Typography>
        <TextField
          label="Email"
          fullWidth
          autoFocus
          placeholder="Input your email"
          type="text"
          name="login"
          value={formData.login}
          onChange={handleChange}
          className={classes.input}
          error={Boolean(errorMessage)}
        />
        <TextField
          label="Password"
          fullWidth
          placeholder="Input your password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className={classes.input}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          name="next"
          onClick={handleSubmit}
          disabled={loading}
        >
          <Box width="100%">Login</Box>
        </Button>
        <ButtonLink to="/restorePassword">Restore password</ButtonLink>
      </Box>
    </DocumentTitle>
  );
};

export default AuthField;
